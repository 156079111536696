@import 'https://fonts.googleapis.com/css?family=Marcellus&subset=latin-ext';

//colors
@frame-bg-color-1: rgba(253,251,251,1);
@frame-bg-color-2: rgba(226,207,208,1);
@footer-bg-color: rgba(4,4,4,0.8);
@header-color: rgba(76,8,11,1);

// Media queries
@screen-xs:     480px;
@screen-sm:     768px;
@screen-md:     992px;
@screen-lg:     1200px;

.frame-corners {
    display:inline-block;
    overflow:hidden;
    background-repeat: no-repeat;
    background-image:url(/img/frame-corners.png);
}

.frame-left-bottom {
    width:83px;
    height:52px;
    background-position: -1px -1px
}

.frame-left-top {
    width:83px;
    height:52px;
    background-position: -86px -1px
}

.frame-right-bottom {
    width:83px;
    height:52px;
    background-position: -171px -1px
}

.frame-right-top {
    width:83px;
    height:52px;
    background-position: -256px -1px
}

.frame-top {
    background: url(/img/frame-top.png) repeat-x;
}

.frame-bottom {
    background: url(/img/frame-bottom.png) repeat-x;
}

.frame-top, .frame-bottom {
    height: 52px;
    margin: 0 83px;
}

body {
    background: #0f0101 url('/img/tlo_glowna.jpg') no-repeat scroll center top;
    font-family: 'Palatino Linotype', 'Book Antiqua', 'Palatino', serif;

    .navbar {
        border: none;
        font-size: 15px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Marcellus', serif;
}

.soft-hidden {
    display: none;
}

.navbar-custom {
    background: #0f0101;
    font-family: 'Marcellus', serif;

    @media (min-width: @screen-sm) {
        background: #0f0101 url('/img/menu-bg.png') repeat center top;
    }

    .navbar-collapse {
        border-top: none;
        border-bottom: none;
    }

    .navbar-nav {
        &:first-child {
            margin-bottom: 0;
        }

        &:last-child {
            margin-top: 0;
        }

        > li {
            background: #0f0101 url('/img/menu-bg.png') repeat center top;

            @media (min-width: @screen-md) {
                &:nth-child(3) {
                    margin-right: 196px;
                }
            }

            > a {
                text-transform: uppercase;
                display: block;
                text-align: center;
                color: #fff;
                text-decoration: none;
                padding: 42px 10px;

                @media (min-width: @screen-md) {
                    padding: 42px 12px;
                }
                @media (min-width: @screen-lg) {
                    padding: 42px 28px;
                }
            }
        }
    }

    .emblem {
        left: 50%;
        margin-left: -105px;
        position: absolute;
    }
}

#navbar-collapse-1 {
    text-align: center;
}

#logo {
    .language-menu {
        padding-right: 30px;

        li {
            padding: 0;
            width: 20px;

            img {
                vertical-align: top;
                margin-top: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    img {
        margin: 30px auto;
    }
}

#main-content {
    padding-left: 60px;
    padding-right: 60px;

    background: url('/img/frame-strips.png') no-repeat left top, url('/img/frame-strips.png') no-repeat right top, linear-gradient(@frame-bg-color-1, @frame-bg-color-2);
    background-size: 52px 100%, 52px 100%, 100% 100%;

    a {
        color: @header-color;
        text-decoration: underline;
    }

    h2 {
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        color: @header-color;
        margin-bottom: 30px;
        margin-top: 0;
    }

    .submenu {
        padding-top: 15px;
        list-style: none;
        padding-left: 0;

        > li {
            > a {
                display: block;
                width: 100%;
                background-color: #e2c7c8;
                text-decoration: none;
                color: @header-color;
                padding: 5px 10px;
                margin-top: 2px;
                border-radius: 5px;
            }

            ul {
                list-style-image: url('/img/pkt.png');

                li {
                    a {
                        display: block;
                        padding: 5px 10px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.ckeditor-content {
    a {
        color: @header-color;
        text-decoration: underline;
    }

    h2 {
        text-align: center;
        color: @header-color;
    }

    p {
        text-align: justify;
    }

    ul {
        list-style-image: url('/img/pkt.png');
    }
}

#map {
    iframe {
        width: 100%;
        height: 480px;
    }
}

#footer {
    font-family: 'Marcellus', serif;
    padding: 20px 20px 5px;
    margin-top: 30px;
    text-align: center;
    background: @footer-bg-color url('/img/footer-bg.png') no-repeat scroll center top;

    a {
        text-transform: uppercase;
        padding: 10px 5px;
        color: #b3989a;
    }
}

 #cookies-policy {
     z-index: 2147483640;
     position: fixed;
     top: auto;
     right: 0;
     bottom: 0;
     left: 0;

     #cookies_policy_submit {
         margin-top: 10px;
         margin-bottom: 10px;
     }

     .cookie-bg {
         position: fixed;
         zoom: 1;
         background-color: rgba(0, 0, 0, 0.8);
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
     }

     .wrapper {
         background: none;
         position: relative;
         z-index: 1000;
         color: white;

         .cookies-text {
             padding: 15px 5px;
         }

         a {
             color: white;
             text-decoration: underline;
         }

         .col-md-10 {
             font-size: 0.688em;
             padding-top: 10px;
             padding-bottom: 10px;
         }

         .col-md-2 {
             padding-top: 10px;
             padding-bottom: 10px;
             text-align: center;

             span {
                 font-size: 0.688em;
             }
         }
     }
}
