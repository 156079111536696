@import 'https://fonts.googleapis.com/css?family=Marcellus&subset=latin-ext';
.frame-corners {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(/img/frame-corners.png);
}
.frame-left-bottom {
  width: 83px;
  height: 52px;
  background-position: -1px -1px;
}
.frame-left-top {
  width: 83px;
  height: 52px;
  background-position: -86px -1px;
}
.frame-right-bottom {
  width: 83px;
  height: 52px;
  background-position: -171px -1px;
}
.frame-right-top {
  width: 83px;
  height: 52px;
  background-position: -256px -1px;
}
.frame-top {
  background: url(/img/frame-top.png) repeat-x;
}
.frame-bottom {
  background: url(/img/frame-bottom.png) repeat-x;
}
.frame-top,
.frame-bottom {
  height: 52px;
  margin: 0 83px;
}
body {
  background: #0f0101 url('/img/tlo_glowna.jpg') no-repeat scroll center top;
  font-family: 'Palatino Linotype', 'Book Antiqua', 'Palatino', serif;
}
body .navbar {
  border: none;
  font-size: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Marcellus', serif;
}
.soft-hidden {
  display: none;
}
.navbar-custom {
  background: #0f0101;
  font-family: 'Marcellus', serif;
}
@media (min-width: 768px) {
  .navbar-custom {
    background: #0f0101 url('/img/menu-bg.png') repeat center top;
  }
}
.navbar-custom .navbar-collapse {
  border-top: none;
  border-bottom: none;
}
.navbar-custom .navbar-nav:first-child {
  margin-bottom: 0;
}
.navbar-custom .navbar-nav:last-child {
  margin-top: 0;
}
.navbar-custom .navbar-nav > li {
  background: #0f0101 url('/img/menu-bg.png') repeat center top;
}
@media (min-width: 992px) {
  .navbar-custom .navbar-nav > li:nth-child(3) {
    margin-right: 196px;
  }
}
.navbar-custom .navbar-nav > li > a {
  text-transform: uppercase;
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 42px 10px;
}
@media (min-width: 992px) {
  .navbar-custom .navbar-nav > li > a {
    padding: 42px 12px;
  }
}
@media (min-width: 1200px) {
  .navbar-custom .navbar-nav > li > a {
    padding: 42px 28px;
  }
}
.navbar-custom .emblem {
  left: 50%;
  margin-left: -105px;
  position: absolute;
}
#navbar-collapse-1 {
  text-align: center;
}
#logo .language-menu {
  padding-right: 30px;
}
#logo .language-menu li {
  padding: 0;
  width: 20px;
}
#logo .language-menu li img {
  vertical-align: top;
  margin-top: 0;
  width: 100%;
  height: auto;
}
#logo img {
  margin: 30px auto;
}
#main-content {
  padding-left: 60px;
  padding-right: 60px;
  background: url('/img/frame-strips.png') no-repeat left top, url('/img/frame-strips.png') no-repeat right top, linear-gradient(#fdfbfb, #e2cfd0);
  background-size: 52px 100%, 52px 100%, 100% 100%;
}
#main-content a {
  color: #4c080b;
  text-decoration: underline;
}
#main-content h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  color: #4c080b;
  margin-bottom: 30px;
  margin-top: 0;
}
#main-content .submenu {
  padding-top: 15px;
  list-style: none;
  padding-left: 0;
}
#main-content .submenu > li > a {
  display: block;
  width: 100%;
  background-color: #e2c7c8;
  text-decoration: none;
  color: #4c080b;
  padding: 5px 10px;
  margin-top: 2px;
  border-radius: 5px;
}
#main-content .submenu > li ul {
  list-style-image: url('/img/pkt.png');
}
#main-content .submenu > li ul li a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
}
.ckeditor-content a {
  color: #4c080b;
  text-decoration: underline;
}
.ckeditor-content h2 {
  text-align: center;
  color: #4c080b;
}
.ckeditor-content p {
  text-align: justify;
}
.ckeditor-content ul {
  list-style-image: url('/img/pkt.png');
}
#map iframe {
  width: 100%;
  height: 480px;
}
#footer {
  font-family: 'Marcellus', serif;
  padding: 20px 20px 5px;
  margin-top: 30px;
  text-align: center;
  background: rgba(4, 4, 4, 0.8) url('/img/footer-bg.png') no-repeat scroll center top;
}
#footer a {
  text-transform: uppercase;
  padding: 10px 5px;
  color: #b3989a;
}
#cookies-policy {
  z-index: 2147483640;
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}
#cookies-policy #cookies_policy_submit {
  margin-top: 10px;
  margin-bottom: 10px;
}
#cookies-policy .cookie-bg {
  position: fixed;
  zoom: 1;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#cookies-policy .wrapper {
  background: none;
  position: relative;
  z-index: 1000;
  color: white;
}
#cookies-policy .wrapper .cookies-text {
  padding: 15px 5px;
}
#cookies-policy .wrapper a {
  color: white;
  text-decoration: underline;
}
#cookies-policy .wrapper .col-md-10 {
  font-size: 0.688em;
  padding-top: 10px;
  padding-bottom: 10px;
}
#cookies-policy .wrapper .col-md-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
#cookies-policy .wrapper .col-md-2 span {
  font-size: 0.688em;
}
